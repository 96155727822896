<template>
  <base-card class="quotes-page">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="quotesList"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2 mainText--text"
        @click:row="chooseRow"
      />
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <general-price-dialog
      v-model="isShowGeneralPriceDialog"
      :price-info="generalPriceInfo"
    />
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  import GeneralPriceDialog from './GeneralPriceDialog'

  export default {
    metaInfo: {
      title: 'Quotes',
    },

    components: {
      GeneralPriceDialog,
    },

    data () {
      return {
        quotesList: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        headers: [],
        generalPriceInfo: {},
        isShowGeneralPriceDialog: false,
        stokabPriceInfo: {},
        isShowStokabPriceDialog: false,
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
      }),
    },

    watch: {
      currentPage(val) {
        this.loadQuotesList(val)
      }
    },

    mounted () {
      if (this.isAdminUser) {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('partner.companyName'),
          value: 'partnerName',
        }, {
          text: this.$t('price.customerName'),
          value: 'customerName',
        }, {
          text: this.$t('common.email'),
          value: 'email',
        }, {
          text: this.$t('address.address'),
          value: 'address',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('price.type'),
          value: 'type',
        }]
      } else if (this.isPartnerAdminUser) {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('price.customerName'),
          value: 'customerName',
        }, {
          text: this.$t('common.email'),
          value: 'email',
        }, {
          text: this.$t('address.address'),
          value: 'address',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('price.type'),
          value: 'type',
        }]
      } else {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('common.email'),
          value: 'email',
        }, {
          text: this.$t('address.address'),
          value: 'address',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('price.type'),
          value: 'type',
        }]
      }

      this.loadQuotesList()
    },

    methods: {
      ...mapActions({
        fetchQuotesList: 'fetchQuotesList',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadQuotesList(currentPage = 1) {
        this.currentPage = currentPage
        const pagination = {
          offset: (this.currentPage - 1) * this.limit,
          limit: this.limit,
        }

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const response = await this.fetchQuotesList(pagination)

          this.totalPages = Math.ceil(response.count / this.limit)
          this.quotesList = response.rows.map((quote, index) => {
            quote.index = (this.currentPage - 1) * this.limit + index + 1
            quote.createdAt = util.formatDateTime(quote.createdAt)

            const quoteDetail = quote.stokabPriceId
              ? quote.stokabPrice
              : quote.requestPrice

            quote.address = util.getFullAddress({
              street: quoteDetail.street,
              street_number: quoteDetail.number,
              letter: quoteDetail.littera,
              postalcode: quoteDetail.postalCode,
              locality: quoteDetail.city,
            })

            quote.customerName = `${
              quoteDetail.requestUser && quoteDetail.requestUser.firstName
            } ${quoteDetail.requestUser && quoteDetail.requestUser.lastName}`

            quote.partnerName =
              quote.requestPrice?.requestUser?.partner?.companyName

            quote.email =
              quoteDetail.requestUser && quoteDetail.requestUser.email
            quote.type = quote.stokabPriceId
              ? this.$t('price.stokabPrice')
              : this.$t('price.generalPrice')

            return quote
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        } finally {
          this.setLoading(false)
        }
      },

      chooseRow(data) {
        this.generalPriceInfo = {
          ...data.requestPrice,
          address: data.address,
        }
        this.isShowGeneralPriceDialog = true
      },
    },
  }
</script>

<style lang="scss">
  .quotes-page {
    .v-data-table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
</style>