<template>
  <v-dialog
    scrollable
    persistent
    :width="900"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title class="white--text" v-text="$t('price.generalPrice')" />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="pa-6"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <template v-if="isAdminUser || isPartnerAdminUser">
          <div class="text-center text-h6 secondary--text font-weight-bold">
            {{ $t("price.customerInfo") }}
          </div>
          <v-row>
            <v-col
              v-if="isAdminUser && companyName"
              cols="12"
              class="pt-0"
            >
              <div class="text-subtitle-2">
                {{ $t("partner.companyName") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ companyName }}
              </div>
            </v-col>

            <v-col cols="4">
              <div class="text-subtitle-2">
                {{ $t("price.customerName") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ customerName }}
              </div>
            </v-col>

            <v-col cols="4">
              <div class="text-subtitle-2">
                {{ $t("common.email") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ priceInfo.requestUser && priceInfo.requestUser.email }}
              </div>
            </v-col>

            <v-col cols="4">
              <div class="text-subtitle-2">
                {{ $t("address.address") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ priceInfo.address }}
              </div>
            </v-col>
          </v-row>
        </template>

        <div
          class="text-center text-h6 secondary--text font-weight-bold"
          :class="isAdminUser || isPartnerAdminUser ? 'mt-6' : 'mt-0'"
        >
          {{ $t("price.priceInfo") }}
        </div>

        <template v-if="priceInfo && priceInfo.requestDetailList">
          <v-row
            v-for="(requestDetailItem, index) in priceInfo.requestDetailList"
            :key="index"
          >
            <v-col cols="12" sm="6" md="3">
              <div class="text-subtitle-2" v-if="index === 0">
                {{ $t("price.speed") }}
              </div>
              <div class="value-field text-subtitle-1 red--text font-weight-bold">
                {{ getSpeedTxt(requestDetailItem) }}
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <div class="text-subtitle-2" v-if="index === 0">
                {{ $t("price.startCost") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ getStartCost(requestDetailItem, priceInfo.requestUser && priceInfo.requestUser.partnerId) }} SEK
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <div class="text-subtitle-2" v-if="index === 0">
                {{ $t("price.monthlyCost") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ getMonthlyCost(requestDetailItem, priceInfo.requestUser && priceInfo.requestUser.partnerId) }} SEK
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <div class="text-subtitle-2" v-if="index === 0">
                {{ $t("access.premises") }}
              </div>
              <div class="value-field text-subtitle-1 black--text">
                {{ getPremisesCost(requestDetailItem, priceInfo.requestUser && priceInfo.requestUser.partnerId) }} SEK
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          @click.native="orderData"
          v-if="priceInfo && priceInfo.networkId">
          {{ $t("order.order") }}
        </v-btn>

        <v-btn
          class="error px-4"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'GeneralPriceDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      priceInfo: {
        type: Object,
        required: true,
      },
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
      }),

      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },

      customerName() {
        if (!this.priceInfo || !this.priceInfo.requestUser) {
          return ''
        }

        return `${this.priceInfo.requestUser.firstName} ${this.priceInfo.requestUser.lastName}`
      },

      companyName() {
        return this.priceInfo.requestUser?.partner?.companyName || ''
      },
    },

    methods: {
      ...mapActions({
        setServiceAddress: 'setServiceAddress',
        setNetworkInfo: 'setNetworkInfo',
        setStokabProductInfo: 'setStokabProductInfo',
        setManualOfferInquiry: 'setManualOfferInquiry',
      }),

      getSpeedTxt(request) {
        const type = this.$t(`price.${request.type}`)
        return request.term
          ? this.$t('price.speedTxtWithMonth', {
              speed: request.speed,
              type,
              month: request.term,
            })
          : this.$t('price.speedTxt', { speed: request.speed, type })
      },

      getStartCost(requestDetailItem, partnerId) {
        if (this.isAdminUser) {
          return partnerId ? requestDetailItem.partnerStart : requestDetailItem.start
        } else {
          return requestDetailItem.start
        }
      },

      getMonthlyCost(requestDetailItem, partnerId) {
        if (this.isAdminUser) {
          return partnerId ? requestDetailItem.partnerMonthly : requestDetailItem.monthly
        } else {
          return requestDetailItem.monthly
        }
      },

      getPremisesCost(requestDetailItem, partnerId) {
        if (this.isAdminUser) {
          return partnerId ? requestDetailItem.partnerPremises : requestDetailItem.premises
        } else {
          return requestDetailItem.premises
        }
      },

      orderData() {
        this.setServiceAddress({
          street: this.priceInfo.street,
          locality: this.priceInfo.city,
          postalCode: this.priceInfo.postalCode,
          streetNumber: this.priceInfo.number,
          streetLetter: this.priceInfo.littera,
        })

        const requestDetail = this.priceInfo.requestDetailList[0]

        this.setNetworkInfo({
          id: this.priceInfo.network.id,
          addOns: this.priceInfo.network.addOns || [],
          customerType: this.priceInfo.network.customerType,
          downSpeed: this.priceInfo.network.downSpeed,
          network: this.priceInfo.network.network,
          nodeType: this.priceInfo.network.nodeType,
          price: requestDetail.monthly,
          speed: this.priceInfo.network.speed,
          start: requestDetail.start,
          term: this.priceInfo.network.term,
          type: this.priceInfo.network.type,
          upSpeed: this.priceInfo.network.upSpeed,
        })
        this.setStokabProductInfo(null)
        this.setManualOfferInquiry(this.priceInfo.offerInquiry)

        const expectedDelivery = requestDetail.expectedDelivery
        this.$router.push(
          `order-price?expectedDelivery=${expectedDelivery}&orderType=manual`
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .value-field {
    border-style: solid;
    border-width: 0 0 thin 0;
    border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
  }
</style>